*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
  text-decoration: none;
  list-style: none;
}
body {
  font-family: "Nunito", sans-serif;
}

.join-waitlist {
  color: #fff;
  background-color: #9d98ec;
  display: inline-block;
  padding: 15px 25px;
  border: none;
  font-weight: 600;
  font-size: 14px;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
}

.form-container {
  width: 100%;
  height: 100%;
  /* border: 1px solid red; */
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  background-color: #00000070;
}

.hide {
  display: none;
}

/* .show */

.t-form {
  width: "100%" !important;
  flex: 1;
}

.close-icon {
  width: 20px;
  height: 20px;
  margin: 0 auto;
  position: absolute;
  right: 20px;
  top: 50px;
  cursor: pointer;
}

.landing {
  width: 100%;
  position: absolute;
  padding: 100px 0px;
  left: 0;
}

.landing-bg-gradient {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent
    linear-gradient(58deg, #00dc99 0%, #63b1cd 46%, #9d98ec 100%) 0% 0%
    no-repeat padding-box;
  opacity: 0.33;
}

.navbar {
  width: 100%;
  min-height: 65px;
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9;
  backdrop-filter: blur(6px);
}

.navbar .logo a img {
  height: 30px;
}

.container {
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  padding: 0px 20px;
}

.container.small {
  max-width: 1000px;
}

.flex-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* landing */

.landing-grid {
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  grid-gap: 50px;
  position: relative;
  z-index: 1;
  align-items: center;
  justify-content: center;
}

.landing-grid-content {
  margin-top: 50px;
}

.landing-grid-content h1 {
  width: 100%;
  max-width: 450px;
  font-weight: 900;
  font-size: 2rem;
  color: #04004d;
  line-height: 1.2;
}

.landing-grid-content p {
  width: 100%;
  max-width: 400px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  margin: 30px 0;
  color: #465969;
}

.landing-grid-image {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.landing-grid-image img {
  width: 100%;
  object-fit: contain;
  margin-top: 50px;
  transform: scale(1.2);
}

/* app-coming-soon */

.app-coming-soon {
  display: flex;
  align-items: center;
  margin-top: 100px;
}

.app-coming-soon span {
  font-size: 14px;
  font-weight: 700;
  color: #465969;
}

.app-coming-soon-image {
  display: flex;
  margin-left: 30px;
}

.app-coming-soon-image span {
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.app-coming-soon-image span img {
  width: 100px;
  border-radius: 5px;
}

.contact {
  font-size: 16px;
  font-weight: 700;
  color: #04004d;
}

.contact:hover {
  text-decoration: underline;
  color: #465969;
}

/* reponsive version */

@media (max-width: 900px) {
  .landing {
    width: 100%;
    height: auto;
  }
  .landing-grid {
    display: flex;
    flex-direction: column-reverse;
  }

  .landing-grid-content h1 {
    font-size: 2rem;
  }

  .landing-grid-image {
    margin-top: 50px;
    justify-content: center;
  }

  .landing-grid-image img {
    width: 80%;
  }

  .app-coming-soon {
    flex-direction: column;
  }

  .app-coming-soon span {
    margin-bottom: 10px;
  }

  .app-coming-soon-image {
    display: flex;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-left: 0;
  }

  .app-coming-soon-image a {
    display: flex;
    align-items: center;
    margin-right: 0;
  }
}

@media (max-width: 500px) {
  .landing-grid-image {
    margin-top: 0;
  }
  .landing-grid-content h1,
  .landing-grid-content p {
    text-align: center;
  }

  .app-coming-soon {
    margin-top: 10px;
  }
  .app-coming-soon-image {
    grid-gap: 10px;
  }

  .join-waitlist {
    color: #fff;
    background-color: #9d98ec;
    display: inline-block;
    padding: 20px 20px;
    border: none;
    font-weight: 600;
    font-size: 14px;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;

    width: 100%;
  }
}

@media (max-width: 370px) {
  .navbar {
    padding: 10px 0;
  }
  .flex-content {
    flex-direction: column;
  }

  .contact {
    margin-top: 10px;
  }
}
